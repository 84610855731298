import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Container, Row, Stack } from 'react-bootstrap'

import {
    ExportInput,
    FILTER_BY_STATUS,
    FilterTicketByDate,
    GROUP_BY,
    MappedTicket,
    SEARCH_BY_KEY,
    TicketsGroup,
} from './types'
import FilterByStatusBar from './FilterByStatusBar'
import FilterByDateBar from './FilterByDateBar'
import GroupedTicketsMenu from './GroupedTicketsMenu'
import SearchBar from './SearchBar'
import TicketItem from './TicketItem'
import TicketsListMenu from './TicketsListMenu'
import TicketsOverview from './TicketsOverview'
import { useGetTicketsByProviderIdQuery } from 'app/api'
import { useTicketAlert, useTickets } from './hooks'
import { getWeekDates } from './utils'
import ResponseAlert from '../../common/alert/ResponseAlert'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

interface DashboardProps {
    providerId: string
}

const Dashboard = ({ providerId }: DashboardProps) => {
    const [searchByKeyValue, setSearchByKeyValue] = useState<string>('')
    const [groupByKey, setGroupByKey] = useState<GROUP_BY | null>(
        GROUP_BY.BS_OBJECT_NAME
    )
    const [filterByStatusValue, setFilterByStatusValue] = useState<
        FILTER_BY_STATUS
    >(FILTER_BY_STATUS.Offen)

    const weekDates = getWeekDates()
    const [filterByDateValue, setFilterByDateValue] = useState<
        FilterTicketByDate
    >({
        startDate: weekDates[0],
        endDate: weekDates[6],
    })

    const { register } = useForm<ExportInput>({})

    const {
        prepareTickets,
        filterTicketsByStatus,
        filterTicketsByKey,
        groupTicketsByKey,
        sortTicketsByHasChanged,
    } = useTickets()
    const { getShowTicketsListChangeAlert } = useTicketAlert()
    const ticketsRequest = {
        providerId: providerId,
        params: {
            from: filterByDateValue.startDate.getTime(),
            to: filterByDateValue.endDate.setHours(23, 59, 59),
        },
    }

    const { data: ticketsData, error } = useGetTicketsByProviderIdQuery(
        ticketsRequest,
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const fetchError = error as FetchBaseQueryError
    let overviewTickets: MappedTicket[] = []
    let tickets: MappedTicket[] = []
    let openTickets: MappedTicket[] = []
    let groupedTickets: TicketsGroup[] = []
    let alertTickets: MappedTicket[] = []
    if (ticketsData) {
        overviewTickets = prepareTickets(ticketsData)
        openTickets = filterTicketsByStatus(
            overviewTickets,
            FILTER_BY_STATUS.Offen
        )
        tickets = filterTicketsByStatus(overviewTickets, filterByStatusValue)
        alertTickets = getShowTicketsListChangeAlert(tickets)
        tickets = sortTicketsByHasChanged(tickets, alertTickets)

        // Search filter have to locate above groupBy filter
        if (searchByKeyValue) {
            tickets = filterTicketsByKey(
                tickets,
                SEARCH_BY_KEY.BS_ROOM_NAME,
                searchByKeyValue
            )
        }

        if (groupByKey) {
            groupedTickets = groupTicketsByKey(tickets, groupByKey)
        }
    }

    const groupHeadline: string =
        groupByKey === GROUP_BY.BS_OBJECT_NAME
            ? 'nach Objekten'
            : groupByKey === GROUP_BY.BS_ROOM_NAME
            ? 'nach Wohneinheiten'
            : 'nicht'

    return (
        <>
            <TicketsOverview
                tickets={overviewTickets}
                openTicketsCount={openTickets.length ?? 0}
                setFilterByDateValue={setFilterByDateValue}
                setFilterByStatusValue={setFilterByStatusValue}
            />
            <section className='container ds-bg-section'>
                <Row className='pt-4 mb-4'>
                    <SearchBar setSearchValue={setSearchByKeyValue} />
                    <FilterByDateBar
                        filterValue={filterByDateValue}
                        setFilterValue={setFilterByDateValue}
                    />
                </Row>
                <Container>
                    <Row className='pt-4'>
                        <div className='d-flex'>
                            <div className='flex-grow-1'>
                                <div className='text-center mb-1'>
                                    <FilterByStatusBar
                                        filterValue={filterByStatusValue}
                                        setFilterValue={setFilterByStatusValue}
                                    />
                                    <div className='text-center mb-0 text-color-n4 ds-btn-group-center'>
                                        <small>
                                            Tickets {groupHeadline} gruppiert.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <TicketsListMenu
                                    tickets={tickets}
                                    groupByKey={groupByKey}
                                    setGroupByKey={setGroupByKey}
                                />
                            </div>
                        </div>
                    </Row>
                </Container>
                <Row className='pt-3 mb-4'>
                    {fetchError &&
                        fetchError.status &&
                        [502, 504].includes(fetchError.status as number) && (
                            <Row className='justify-content-center my-4'>
                                <Col>
                                    <ResponseAlert
                                        status={400}
                                        message={
                                            'Beim Laden der Reinigungstickets ist ein Fehler aufgetreten. Bitte schränken Sie den Zeitraum weiter ein.'
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    {!groupByKey ? (
                        <Stack direction='vertical' gap={3}>
                            {tickets?.map((ticket, index) => (
                                <TicketItem
                                    key={`ticket-item-${ticket.id}`}
                                    ticket={ticket}
                                    index={index + 1}
                                    register={register}
                                />
                            ))}
                        </Stack>
                    ) : (
                        groupedTickets.map((ticketGroup, index) => (
                            <Row
                                className='pt-3 mb-4'
                                key={`ticket-group-${index}`}
                            >
                                <div className='d-flex mb-3'>
                                    <div className='flex-grow-1 align-self-center'>
                                        <h4 className='mb-0'>
                                            {ticketGroup.groupId}
                                        </h4>
                                    </div>
                                    <GroupedTicketsMenu
                                        tickets={ticketGroup.tickets}
                                    />
                                </div>
                                <Stack direction='vertical' gap={3}>
                                    {ticketGroup.tickets.map(
                                        (ticket, index) => (
                                            <TicketItem
                                                key={`ticket-item-${ticket.id}`}
                                                ticket={ticket}
                                                index={index + 1}
                                                register={register}
                                            />
                                        )
                                    )}
                                </Stack>
                            </Row>
                        ))
                    )}
                </Row>
            </section>
        </>
    )
}

export default Dashboard
